<template>
  <div>
    <h1>Author</h1>
    <h2>ID {{ authorId }}</h2>
  </div>
</template>

<script>
export default {
  name: 'AuthorPage',
  computed: {
    authorId: function () {
      return this.$route.params.author.split('-').pop()
    }
  }
}
</script>
